import { useAppContext } from "@/context";
import { db } from "@/core";
import { type SubcontractorPublishEntry, moment } from "@msuite/katana";
import {
	ContentContainerBody,
	ContentContainerHeading,
	Heading,
	ModalControllerContainer,
	TimesheetControllerForm,
	useDocument,
} from "@msuite/picasso";
import { type FC, useRef } from "react";
import { useEditOwnTimesheetsModalStore } from "./store";

export const TimesheetController: FC = () => {
	/** Context */
	const { subcontractorId } = useAppContext();

	/** Hooks */
	const { currentEntryId, resetCurrentEntryId } =
		useEditOwnTimesheetsModalStore();
	const footerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const { data: entry } = useDocument<SubcontractorPublishEntry>(db, {
		path: `unternehmer/${subcontractorId}/subunternehmer_timeline/${currentEntryId}`,
		subscribe: false,
		dependencies: ["control"],
	});

	if (!currentEntryId) return null;

	/** Render */
	return (
		<ModalControllerContainer>
			<ContentContainerHeading>
				<Heading>
					{moment(entry?.date).format("L")} / {entry?.baustelle.bauvorhaben}
				</Heading>
			</ContentContainerHeading>
			<ContentContainerBody>
				<TimesheetControllerForm
					key={currentEntryId}
					subcontractorId={subcontractorId}
					onComplete={resetCurrentEntryId}
					onDismiss={resetCurrentEntryId}
					entryId={currentEntryId}
					footerRef={footerRef}
					mode="create"
				/>
			</ContentContainerBody>
			<div ref={footerRef} />
		</ModalControllerContainer>
	);
};
