import { DASHBOARD_CONSTRUCTION_SITES_MAP_LAYOUT_ID } from "@/sites/home/use-dashboard";
import {
	Box,
	ConstructionSitesMap,
	Modal,
	ModalContent,
	ModalOverlay,
	useConstructionSitesMapStore,
} from "@msuite/picasso";
import { TbX } from "react-icons/tb";

export const ConstructionSitesMapModal = () => {
	/** State */
	const { isOpen, onClose, constructionSites } = useConstructionSitesMapStore();

	/** Render */
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				layoutId={DASHBOARD_CONSTRUCTION_SITES_MAP_LAYOUT_ID}
				width="70vw"
				height="80vh"
				position="relative"
			>
				<Box
					position="absolute"
					left="-5rem"
					right="-5rem"
					bottom="-5rem"
					top="-5rem"
				>
					<ConstructionSitesMap
						constructionSites={constructionSites}
						showMinimalDetails
					/>
				</Box>
				<Box
					position="absolute"
					top="1rem"
					right="1rem"
					padding="1rem"
					cursor="pointer"
					rounded="lg"
					borderWidth={1}
					style={{
						backdropFilter: "blur(10px) brightness(0.9)",
					}}
					onClick={onClose}
				>
					<TbX />
				</Box>
			</ModalContent>
		</Modal>
	);
};
