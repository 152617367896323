import { type Moment, moment } from "@msuite/katana";
import { create } from "zustand";

interface EditOwnTimesheetsModalStore {
	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;
	currentWeek: Moment;
	goBack: () => void;
	goForward: () => void;
	setCurrentWeek: (currentWeek: Moment) => void;
	currentEntryId: string | null;
	setCurrentEntryId: (currentEntryId: string) => void;
	resetCurrentEntryId: () => void;
}

export const useEditOwnTimesheetsModalStore =
	create<EditOwnTimesheetsModalStore>((set) => ({
		isOpen: false,
		onClose: () => set({ isOpen: false }),
		onOpen: () => set({ isOpen: true }),
		currentWeek: moment(),
		goBack: () =>
			set(({ currentWeek }) => ({
				currentWeek: currentWeek.clone().subtract(1, "week"),
				currentEntryId: null,
			})),
		goForward: () =>
			set(({ currentWeek }) => ({
				currentWeek: currentWeek.clone().add(1, "week"),
				currentEntryId: null,
			})),
		setCurrentWeek: (currentWeek: Moment) =>
			set({ currentWeek, currentEntryId: null }),
		currentEntryId: null,
		setCurrentEntryId: (currentEntryId: string) =>
			set((store) => {
				const current = store.currentEntryId;
				if (current !== currentEntryId) {
					return { currentEntryId };
				}
				return { currentEntryId: null };
			}),
		resetCurrentEntryId: () => set({ currentEntryId: null }),
	}));
