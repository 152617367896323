import { queryClient } from "@/core";
import {
	type MenuItems,
	type NavigationLinks,
	useCoreContext,
} from "@msuite/picasso";
import { signOut } from "firebase/auth";
import { TbLogout } from "react-icons/tb";
import packageJson from "../../package.json";

export function useNavigationLinks() {
	/** Context */
	const { auth } = useCoreContext();

	/** Render */
	function createMenuItems(): MenuItems {
		return [
			{
				id: "app-information",
				render: `${packageJson.name} ${packageJson.version}`,
				onClick: undefined,
			},
			"divider",
			{
				icon: <TbLogout />,
				label: "Logout",
				onClick: async () => {
					await signOut(auth);
					await queryClient.clear();
				},
			},
		];
	}

	function createNavigationLinks(): NavigationLinks {
		return [
			{ label: "Home", icon: null, to: "/home", associatedRoutes: ["/"] },
			{
				label: "Tagesplan",
				icon: null,
				to: "/plan",
				associatedRoutes: ["/plan"],
			},
			{
				label: "Timeline",
				icon: null,
				to: "/timeline",
				associatedRoutes: ["/timeline"],
			},
			{
				label: "Dokumente",
				icon: null,
				to: "/documents",
				associatedRoutes: ["/documents"],
			},
		];
	}

	const navigationLinks = createNavigationLinks();
	const menuItems = createMenuItems();

	return {
		navigationLinks,
		menuItems,
	};
}
