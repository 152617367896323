import { ax } from "@/core";
import type { IGetSubcontractorUserCompany } from "@msuite/katana";
import { useApi, useAuthContext } from "@msuite/picasso";

export const useSubcontractorMeta = () => {
	/** Hooks */
	const { userId } = useAuthContext();

	/** Hooks */
	const { data, isFetching } = useApi<IGetSubcontractorUserCompany>(ax, {
		url: `/v2/subcontractor/${userId}/company`,
		dependencies: ["subcontractor", "company", userId ?? "userId"],
		staleTime: Number.POSITIVE_INFINITY,
		disabled: !userId,
	});

	return {
		subcontractor: data?.subcontractor,
		subcontractorId: data?.subcontractorId,
		isFetching,
	};
};
