import {
	ComponentLoader,
	ContentLayout,
	PasswordReset,
	PrivateRoute,
} from "@msuite/picasso";
import { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { NavigationWrapper } from "./components/navigation-wrapper";
import { AppContext } from "./context";
import { useSubcontractorMeta } from "./hooks/use-subcontractor-meta";
import { Home } from "./sites/home";

const LOGO_SUFFIX = "Partners";
const ADDITIONAL_AUTH_CONDITION_ERROR =
	"Dieser Bereich ist nur für Subunternehmer zugänglich.";

const Plan = lazy(() => import("./sites/plan"));
const Documents = lazy(() => import("./sites/documents"));
const Timeline = lazy(() => import("./sites/timeline"));

function App() {
	/** Hooks */
	const { subcontractor, subcontractorId, isFetching } = useSubcontractorMeta();

	/** Render */
	return (
		<AppContext.Provider value={{ subcontractor, subcontractorId }}>
			<BrowserRouter>
				<ContentLayout>
					<NavigationWrapper>
						<Suspense fallback={<ComponentLoader />}>
							<Routes>
								<Route
									path="/reset-password"
									element={<PasswordReset logoSuffix={LOGO_SUFFIX} />}
								/>
								<Route
									path="/"
									element={
										<PrivateRoute
											logoSuffix={LOGO_SUFFIX}
											resetPasswordPath="/reset-password"
											additionalAuthCondition={!!subcontractor}
											additionalAuthConditionIsLoading={isFetching}
											additionalAuthConditionErrorMessage={
												ADDITIONAL_AUTH_CONDITION_ERROR
											}
										/>
									}
								>
									<Route path="/" element={<Navigate to="/home" />} />
									<Route path="/home" element={<Home />} />
									<Route path="/plan" element={<Plan />} />
									<Route path="/documents" element={<Documents />} />
									<Route path="/timeline" element={<Timeline />} />
								</Route>
							</Routes>
						</Suspense>
					</NavigationWrapper>
				</ContentLayout>
			</BrowserRouter>
		</AppContext.Provider>
	);
}

export default App;
