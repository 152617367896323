import type { SubcontractorPublishEntry } from "@msuite/katana";
import { getConstructionSiteAddressString, romanize } from "@msuite/katana";
import {
	Box,
	Grid,
	Text,
	TimesheetBadge,
	VStack,
	useUIContext,
} from "@msuite/picasso";
import type { FC } from "react";
import { useEditOwnTimesheetsModalStore } from "./store";

/** Props Interface */
interface ListItemProps {
	entry: SubcontractorPublishEntry;
}

export const ListItem: FC<ListItemProps> = ({ entry }) => {
	/** Context */
	const { colors } = useUIContext();
	const { currentEntryId, setCurrentEntryId } =
		useEditOwnTimesheetsModalStore();

	const isActive = currentEntryId === entry.id;

	/** Render */
	return (
		<VStack
			pl={8}
			pr={4}
			position="relative"
			py={2}
			spacing={1}
			cursor="pointer"
			_hover={{ opacity: 0.75 }}
			_active={{ opacity: 0.5 }}
			onClick={() => setCurrentEntryId(entry.id)}
			bg={isActive ? colors.grayLightDarker : undefined}
			_notLast={
				!isActive
					? {
							'*[id="list-item-border"]': { display: "flex" },
						}
					: {}
			}
		>
			<Grid templateColumns="1fr max-content">
				<Text size="sm" fontWeight="semibold" noOfLines={1}>
					{entry.baustelle.bauvorhaben}
				</Text>
				<TimesheetBadge state={entry.timesheet?.controlled} />
			</Grid>
			<Text size="sm" noOfLines={1}>
				{getConstructionSiteAddressString(entry.baustelle)}
			</Text>
			<Text size="sm">
				{entry.kolonne.name} {romanize(entry.kolonne.rating)}
			</Text>
			<Box
				display="none"
				id="list-item-border"
				position="absolute"
				left="0"
				bottom="0"
				right="0"
				ml={8}
				borderBottomWidth={1}
			/>
		</VStack>
	);
};
