import type { ConstructionSite } from "@/components/construction-site/construction-site-card";
import { ax } from "@/core";
import type { IGetSubcontractorDailyResponse } from "@msuite/katana";
import { useApi, useConstructionSitesMapStore } from "@msuite/picasso";
import { useEffect } from "react";

export const useConstructionSitesMap = () => {
	/** State */
	const { currentDate, setConstructionSites } = useConstructionSitesMapStore();

	/** Hooks */
	const { data } = useApi<IGetSubcontractorDailyResponse>(ax, {
		url: `/v2/subcontractors/daily/${currentDate.daystampDb()}`,
		dependencies: ["subcontractor", "daily", currentDate.daystampDb()],
		staleTime: 10_000,
	});

	/** Effects */
	useEffect(() => {
		if (!data) return;
		const constructionSites = data?.subcontractorDaily
			.flatMap((d) => d.kolonnen)
			.flatMap((c) => c.baustellen)
			.filter(Boolean) as ConstructionSite[];
		if (!constructionSites?.length) {
			setConstructionSites([]);
		} else {
			setConstructionSites(constructionSites ?? []);
		}
	}, [data, setConstructionSites]);
};
