import { useNavigationLinks } from "@/hooks/use-navigation-links";
import { Navigation, NavigationContext } from "@msuite/picasso";
import type { FC, ReactNode } from "react";

/** Props Interface */
interface NavigationWrapperProps {
	children?: ReactNode;
}

export const NavigationWrapper: FC<NavigationWrapperProps> = ({ children }) => {
	/** Hooks */
	const { menuItems, navigationLinks } = useNavigationLinks();

	/** Render */
	return (
		<NavigationContext.Provider value={{ navigationLinks }}>
			<Navigation
				menuItems={menuItems}
				navigationLinks={navigationLinks}
			/>
			{children}
		</NavigationContext.Provider>
	);
};
