import { useEditOwnTimesheetsModalStore } from "@/modals/edit-own-timesheets-modal/store";
import {
	type Tiles,
	useAuthContext,
	useConstructionSitesMapStore,
} from "@msuite/picasso";
import { useNavigate } from "react-router-dom";

export const DASHBOARD_OWN_TIMESHEETS_LAYOUT_ID = "dashboard-own-timesheets";
export const DASHBOARD_CONSTRUCTION_SITES_MAP_LAYOUT_ID =
	"dashboard-construction-sites";

export function useDashboard() {
	/** Context */
	const { validation, userId } = useAuthContext();
	const editOwnTimesheetsModalStore = useEditOwnTimesheetsModalStore();
	const constructionSitesMapTileStore = useConstructionSitesMapStore();
	const navigate = useNavigate();

	/** Functions */
	function buildTiles() {
		return buildSubcontractorTiles();
	}

	function handleOnNavigateToDocuments() {
		navigate("/documents");
	}

	function buildSubcontractorTiles() {
		const tiles: Tiles = [
			{
				type: "external-subcontractor-own-timesheets",
				layoutId: DASHBOARD_OWN_TIMESHEETS_LAYOUT_ID,
				onClick: editOwnTimesheetsModalStore.onOpen,
			},
			{
				type: "construction-sites-map-tile",
				layoutId: DASHBOARD_CONSTRUCTION_SITES_MAP_LAYOUT_ID,
				onClick: constructionSitesMapTileStore.onOpen,
			},
			{
				type: "external-subcontractor-documents",
				onClick: handleOnNavigateToDocuments,
			}
		];
		return tiles;
	}

	const tiles = buildTiles();

	/** Return */
	return {
		validation,
		userId,
		tiles,
	};
}
