import { BlankSlate } from "@msuite/picasso";
import type { FC } from "react";
import { useEditOwnTimesheetsModalStore } from "./store";
import { TimesheetController } from "./timesheet-controller";

export const Content: FC = () => {
	const { currentEntryId } = useEditOwnTimesheetsModalStore();

	/** Render */
	return !currentEntryId ? (
		<BlankSlate
			heading="Leistung auswählen"
			subtext="Wählen Sie eine Leistung aus, um einen Stundenzettel zu bearbeiten."
		/>
	) : (
		<TimesheetController />
	);
};
