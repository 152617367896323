import { useAppContext } from "@/context";
import { moment } from "@msuite/katana";
import {
	BlankSlate,
	HStack,
	Heading,
	VStack,
	useSubcontractorTimelineDay,
} from "@msuite/picasso";
import type { FC } from "react";
import { ListItem } from "./list-item";

/** Props Interface */
interface DaySectionProps {
	day: string; // YYYY-MM-DD
}

export const DaySection: FC<DaySectionProps> = ({ day }) => {
	/** Context */
	const { subcontractorId } = useAppContext();

	/** Hooks */
	const currentDay = moment(day);
	const { timeline } = useSubcontractorTimelineDay(
		subcontractorId,
		currentDay.format("YYYY-MM-DD"),
	);

	/** Render */
	return (
		<VStack
			spacing={0}
			_notLast={{
				borderBottomWidth: 1,
			}}
		>
			<HStack pr={4} ml={4} py={3} borderBottomWidth={1}>
				<Heading size="sm">{currentDay.format("dddd, DD.MM.YYYY")}</Heading>
			</HStack>
			{!timeline.length && (
				<BlankSlate
					minH="0"
					icon={<div />}
					heading="Keine Aufträge"
					subtext=""
				/>
			)}
			{timeline.map((entry) => (
				<ListItem key={entry.id} entry={entry} />
			))}
		</VStack>
	);
};
