import type { Subcontractor } from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface IAppContext {
  subcontractorId: string | undefined;
  subcontractor: Subcontractor | undefined;
}

/** Context */
export const AppContext = createContext<IAppContext>({} as IAppContext)

/** Hooks */
export const useAppContext = () => {
  return useContext(AppContext)
}