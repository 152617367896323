import { DASHBOARD_OWN_TIMESHEETS_LAYOUT_ID } from "@/sites/home/use-dashboard";
import {
	ContentContainer,
	Modal,
	ModalCloseButton,
	ModalContentContainer,
	ModalOverlay,
} from "@msuite/picasso";
import type { FC } from "react";
import { Content } from "./content";
import { List } from "./list";
import { useEditOwnTimesheetsModalStore } from "./store";

export const EditOwnTimesheetsModal: FC = () => {
	/** State */
	const { isOpen, onClose } = useEditOwnTimesheetsModalStore();

	/** Render */
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContentContainer
				width="90rem"
				layoutId={DASHBOARD_OWN_TIMESHEETS_LAYOUT_ID}
			>
				<ModalCloseButton />
				{isOpen && (
					<ContentContainer>
						<List />
						<Content />
					</ContentContainer>
				)}
			</ModalContentContainer>
		</Modal>
	);
};
