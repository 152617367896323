import { ListContainer } from "@msuite/picasso";
import type { FC } from "react";
import { DaySection } from "./day-section";
import { useEditOwnTimesheetsModalStore } from "./store";

export const List: FC = () => {
	/** State */
	const { goBack, goForward, currentWeek } = useEditOwnTimesheetsModalStore();

	/** Render */
	return (
		<ListContainer
			heading={currentWeek.format("WW / YYYY")}
			goBack={goBack}
			goForward={goForward}
		>
			{Array.from({ length: 7 }).map((_, index) => {
				const currentDate = currentWeek
					.clone()
					.startOf("week")
					.isoWeekday(index + 1);
				const daystamp = currentDate.daystampDb();
				return <DaySection key={daystamp} day={daystamp} />;
			})}
		</ListContainer>
	);
};
