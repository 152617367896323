import {
	ConstructionSitesMapModal,
	useConstructionSitesMap,
} from "@/modals/construction-sites-map-modal";
import { EditOwnTimesheetsModal } from "@/modals/edit-own-timesheets-modal";
import { DashboardWrapper, useTitle } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { useDashboard } from "./use-dashboard";

export const Home: FC = () => {
	/** Hooks */
	const { tiles } = useDashboard();
	useTitle("Home");
	useConstructionSitesMap();

	/** Render */
	return (
		<Fragment>
			<DashboardWrapper
				tiles={tiles}
				alerts={[]}
				workspaceIds={[]}
				activeWorkspace=""
				setActiveWorkspace={() => { }}
			/>
			<EditOwnTimesheetsModal />
			<ConstructionSitesMapModal />
		</Fragment>
	);
};
